import React from "react"

const SmLegendSymbol = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 70 70"
  >
    <title>cos-zap-legend</title>
    <g id="zap-legend">
      <path fill="#EDC80F" d="M34.9,7.5L7.4,35l27.5,27.5L62.5,35L34.9,7.5z" />
      <path fill="#E93D07" d="M17.8,30.8l-12-12L3,21.5l12,12L17.8,30.8z" />
      <path fill="#E93D07" d="M25.7,22.9l-12-12l-2.8,2.8l12,12L25.7,22.9z" />
      <path fill="#E93D07" d="M33.5,15l-12-12l-2.8,2.8l12,12L33.5,15z" />
      <path fill="#E93D07" d="M51.3,64.3l-12-12L36.5,55l12,12L51.3,64.3z" />
      <path fill="#E93D07" d="M59.2,56.4l-12-12l-2.8,2.8l12,12L59.2,56.4z" />
      <path fill="#E93D07" d="M67,48.5l-12-12l-2.8,2.8l12,12L67,48.5z" />
      <path fill="#E93D07" d="M39.2,17.8l12-12L48.4,3l-12,12L39.2,17.8z" />
      <path fill="#E93D07" d="M47.1,25.7l12-12l-2.8-2.8l-12,12L47.1,25.7z" />
      <path fill="#E93D07" d="M54.9,33.5l12-12l-2.8-2.8l-12,12L54.9,33.5z" />
      <path fill="#E93D07" d="M5.7,51.3l12-12l-2.8-2.8l-12,12L5.7,51.3z" />
      <path fill="#E93D07" d="M13.6,59.2l12-12l-2.8-2.8l-12,12L13.6,59.2z" />
      <path fill="#E93D07" d="M21.4,67l12-12l-2.8-2.8l-12,12L21.4,67z" />
    </g>
    <g id="cog-legend">
      <rect fill="#FFFDFB" width="70" height="70" />
      <path
        fill="#A7D6CC"
        d="M35,52.6c9.7,0,17.6-7.9,17.6-17.7s-7.9-17.6-17.7-17.6c-9.7,0-17.6,7.9-17.6,17.6
C17.3,44.7,25.2,52.6,35,52.6C35,52.6,35,52.6,35,52.6z"
      />
      <path
        fill="#0A4874"
        d="M26.5,16.5L20.6,6.3l-3.2,1.9l5.9,10.2C24.3,17.7,25.3,17.1,26.5,16.5z"
      />
      <path
        fill="#0A4874"
        d="M18.4,23.2L8.1,17.3l-1.9,3.2l10.2,5.9C17.1,25.3,17.7,24.3,18.4,23.2z"
      />
      <path
        fill="#0A4874"
        d="M14.6,35c0-0.6,0-1.3,0.1-1.9H2.9v3.8h11.8C14.7,36.3,14.6,35.6,14.6,35z"
      />
      <path
        fill="#0A4874"
        d="M35,14.6c0.6,0,1.3,0,1.9,0.1V2.9h-3.8v11.8C33.7,14.7,34.4,14.6,35,14.6z"
      />
      <path
        fill="#0A4874"
        d="M46.7,18.4l5.9-10.2l-3.2-1.9l-5.9,10.2C44.6,17,45.7,17.7,46.7,18.4z"
      />
      <path
        fill="#0A4874"
        d="M53.5,26.5l10.2-5.9l-1.9-3.2l-10.2,5.9C52.3,24.3,52.9,25.4,53.5,26.5z"
      />
      <path
        fill="#0A4874"
        d="M51.6,46.7l10.2,5.9l1.9-3.2l-10.2-5.9C52.9,44.6,52.3,45.6,51.6,46.7z"
      />
      <path
        fill="#0A4874"
        d="M16.5,43.5L6.3,49.4l1.9,3.2l10.2-5.9C17.7,45.7,17.1,44.6,16.5,43.5z"
      />
      <path
        fill="#0A4874"
        d="M43.5,53.5l5.9,10.2l3.2-1.9l-5.9-10.2C45.7,52.3,44.6,52.9,43.5,53.5z"
      />
      <path
        fill="#0A4874"
        d="M55.3,33.2c0.1,0.6,0.1,1.2,0.1,1.9c0,0.6,0,1.3-0.1,1.9h11.8v-3.7H55.3z"
      />
      <path
        fill="#0A4874"
        d="M35,55.3c-0.6,0-1.3,0-1.9-0.1v11.8h3.8V55.2C36.3,55.3,35.6,55.4,35,55.3z"
      />
      <path
        fill="#0A4874"
        d="M23.2,51.6l-5.9,10.2l3.2,1.9l5.9-10.2C25.4,52.9,24.3,52.3,23.2,51.6z"
      />
    </g>
  </svg>
)

export default SmLegendSymbol
